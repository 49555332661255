import TablePaginationActions from "@/components/base/table/PaginationActions"
import { PostEntry } from "@/types/socials"
import { paginate } from "@/utils/paginate"
import { List, Paper, TablePagination, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "../style"
import { SocialsDraftItem } from "./item"

export const SocialsDraftPost = ({
    posts,
    onPostClick,
}: {
    posts: Partial<PostEntry>[]
    onPostClick: (id: string) => void
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const [page, setPage] = useState<number>(0)
    const handleChangePage = useCallback((newPage: number) => {
        setPage(newPage)
    }, [])

    const filteredData = useMemo(() => {
        const response = paginate(posts, page === 0 ? 1 : page + 1, 10)
        return response.items || []
    }, [posts, page])

    return (
        <Paper elevation={1}>
            <Typography variant={"h2"} className="text-number">
                {t("SOCIALS.DRAFT_RESUME")}
            </Typography>
            <List className={classes.list}>
                {filteredData.map((post) => (
                    <SocialsDraftItem post={post} key={post.id} onPostClick={onPostClick} />
                ))}
            </List>
            <TablePagination
                className={classes.pagination}
                component={"div"}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${t("USERS.PAGING")} ${count}`
                }}
                count={posts.length}
                rowsPerPage={10}
                page={page}
                onPageChange={(__, pageActive) => {
                    handleChangePage(pageActive)
                }}
                rowsPerPageOptions={[]}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    )
}
