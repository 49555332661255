import { SocialsApi } from "@/api"
import Icon from "@/components/base/Icon"
import { BtnDotsHorizontalDelete } from "@/components/btn-dots-horizontal-delete"
import usePageLogic from "@/hooks/social/usePageLogic"
import useNotification from "@/hooks/useNotification"
import { PostEntry } from "@/types/socials"
import { videoListExtension } from "@/data/constants"
import { CardMedia, Paper, Stack, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

export const SocialsDraftItem = ({ post, onPostClick }: { post: PostEntry; onPostClick: (id: string) => void }) => {
    const { notif } = useNotification()
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const { fetchPosts } = usePageLogic()

    const handleRemove = useCallback(async () => {
        setLoading(true)
        const res = await SocialsApi.removePost(post?.id)
        if (res?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            await fetchPosts()
            notif({ message: t("SOCIALS.SUCCESS_DELETE_DRAFT"), type: "SUCCESS" })
        }
        setLoading(false)
    }, [post?.id, fetchPosts])

    const hasCover = useMemo(() => post.thumbnail && post.thumbnail !== undefined, [post.thumbnail])

    const typeThumbnail = useMemo(() => {
        if (post.thumbnail && post.thumbnail !== "") {
            const mediaExtension = post.thumbnail?.split(".").pop().toLowerCase()
            if (videoListExtension.includes(mediaExtension)) {
                return "VIDEO"
            }
            return "PHOTO"
        }
        return "PHOTO"
    }, [post?.thumbnail, videoListExtension])

    return (
        <Paper elevation={2} className="item-draft">
            <Stack padding={1} gap={1}>
                <Stack
                    className="header-content"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    height={"100%"}
                >
                    <Stack
                        flexDirection={"row"}
                        gap={0.5}
                        onClick={() => onPostClick(post.id)}
                        width={"100%"}
                        height={"100%"}
                    >
                        {post.provider.includes("facebook") && (
                            <Icon name="facebookSocial" sx={{ width: 16, height: 16 }} />
                        )}
                        {post.provider.includes("instagram") && (
                            <Icon name="instagramSocial" sx={{ width: 16, height: 16 }} />
                        )}
                        {post.provider.includes("google") && <Icon name="googleSocial" />}
                    </Stack>
                    <BtnDotsHorizontalDelete handleRemove={handleRemove} isLoading={loading} />
                </Stack>
                {post.description !== "" && (
                    <Stack onClick={() => onPostClick(post.id)} className="bloc-description">
                        <Typography
                            variant="caption"
                            component="span"
                            className={post?.thumbnail ? "ellipse-two-line" : ""}
                        >
                            {post.description}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Stack className="draft-content" gap={1} onClick={() => onPostClick(post.id)}>
                {post?.thumbnail && (
                    <>
                        {typeThumbnail === "PHOTO" && (
                            <Stack
                                onClick={() => onPostClick(post.id)}
                                sx={{
                                    overflow: "hidden",
                                    backgroundSize: "cover",
                                    height: "165px",
                                    ...(hasCover
                                        ? { background: `url(${post.thumbnail}) lightgray 50% / cover no-repeat` }
                                        : { background: "#F4F5FA" }),
                                }}
                            ></Stack>
                        )}
                        {typeThumbnail === "VIDEO" && (
                            <CardMedia
                                height={post.description ? "165px" : "100%"}
                                component="video"
                                image={post.thumbnail}
                            />
                        )}
                    </>
                )}
            </Stack>
        </Paper>
    )
}
