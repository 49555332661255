import Icon from "@/components/base/Icon"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const GoogleAlertImage = () => {
    const { t } = useTranslation()
    return (
        <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
            <Icon name="info" sx={{ color: "info.main" }} />
            <Typography variant="body2" color={"#014361"} fontFamily={"Poppins Medium"}>
                {t("SOCIALS.GOOGLE_FIRST_IMAGE")}
            </Typography>
        </Stack>
    )
}
