import { DispatchrService } from "@/api"
import { PostFile } from "@/api/reseller"
import { PostByDate } from "@/store/social"
import { SelectType } from "@/types/select"
import { IPostItems, PostEntry, PostResponse, Provider } from "@/types/socials"
import { groupByKey } from "@/utils"
import { imgListExtension } from "@/data/constants"
import { getMediaRatio } from "@/utils/media-ratio"
import dayjs from "dayjs"

const statusProviderName = {
    facebook: "facebookStatus",
    google: "status",
    instagram: "instagramStatus",
}
class SocialService {
    formatMedia(postMedia: PostFile) {
        const newMedias = []
        postMedia?.medias?.forEach((media) => {
            const metadata = getMediaRatio({
                naturalSize: { width: media.dimensions.width, height: media.dimensions.height },
            })
            const mediaExtension = media?.url?.split(".").pop().toLowerCase()
            newMedias.push({
                url: media.url,
                metadata,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            })
        })
        return newMedias
    }

    formatPostItems({ locationIds, dateToPublish, businessesTimezone, status, providersLocation, oldPostItems }) {
        const postItems: IPostItems[] = []
        for (const [provider, providerLocation] of Object.entries(providersLocation)) {
            ;(providerLocation as string[]).map((location) => {
                const isInLocationIds = locationIds.find((locationId) => locationId === location)
                const oldPostItem = oldPostItems?.find(
                    (postItem) => postItem.locationId === location && postItem.provider === String(provider)
                )

                if (isInLocationIds) {
                    let input: IPostItems = {
                        locationId: location,
                        provider: provider === "null" ? null : (provider as Provider),
                        scheduledAtUtc: dateToPublish
                            ? dayjs(dateToPublish).tz(businessesTimezone[location]).format("YYYY-MM-DDTHH:mm:ss")
                            : null,
                        scheduledAt: dateToPublish,
                        status: status,
                    }
                    if (oldPostItem) {
                        input = { ...input, postId: oldPostItem.postId, id: oldPostItem.id }
                    }
                    postItems.push(input)
                }
            })
        }

        return postItems
    }

    formatPostItemsError({ postItems, publishDate, businessesTimezone, status }) {
        const postsError = postItems.filter((item) => item.status === "FAILED")
        const postItemsError = []
        const postItemsWithoutError = []

        if (postsError.length > 0) {
            postsError.forEach((postError) => {
                const newPostError = {
                    ...postError,
                    scheduledAtUtc: dayjs(publishDate)
                        .tz(businessesTimezone[postError.locationId])
                        .format("YYYY-MM-DDTHH:mm:ss"),
                    scheduledAt: publishDate,
                    status: status,
                }

                postItemsError.push(newPostError)
            })
        }

        // Collect all postItems that are not in postItemsError
        postItems.forEach((items) => {
            const hasError = postItemsError.some(
                (errorItem) => errorItem.locationId === items.locationId && errorItem.provider === items.provider
            )

            if (!hasError) {
                postItemsWithoutError.push(items)
            }
        })

        return postItemsError.concat(postItemsWithoutError)
    }

    hidePostAlert(locationIds: string[]) {
        return DispatchrService.put("location-state", {
            locationIds,
            showPostAlert: false,
        })
    }

    formatRowsPostError(_rows = [], businesses: SelectType[]) {
        const rowMap = {}
        const rows = Array.isArray(_rows) ? _rows : []
        const _provider = []

        for (const row of rows) {
            const locationName = businesses.find((business) => business.value === row.locationId)?.label
            const locationAddress = businesses.find((business) => business.value === row.locationId)?.shortAddress

            rowMap[row.locationId] ??= {
                name: locationName,
                address: locationAddress,
            }
            rowMap[row.locationId][row.provider] = row.status !== "FAILED"
            if (!_provider.includes(row.provider)) {
                _provider.push(row.provider)
            }
        }

        return {
            provider: _provider,
            rows: Object.values(rowMap),
        }
    }

    getPostStatus(postItems: IPostItems[]) {
        let status = ""
        for (const item of postItems) {
            if (item.status === "FAILED") return "LIVE_W_ERROR"
            if (item.status === "DRAFT") return "DRAFT"
            status = item.status
        }
        return status
    }

    getPostProvider(postItems: IPostItems[]) {
        let provider = []
        for (const item of postItems) {
            if (item?.provider) provider.push(item.provider)
        }
        return [...new Set(provider)]
    }

    getPostLocationIds(postItems: IPostItems[]) {
        let locationIds = []
        for (const item of postItems) {
            locationIds.push(item.locationId)
        }
        return [...new Set(locationIds)]
    }

    formatPostForm({ post, locationIdSingle }: { post: PostResponse; locationIdSingle: string[] }) {
        const isScheduled = this.getPostStatus(post?.postItems) === "SCHEDULED"
        const scheduledAtWithUtc = dayjs(post?.postItems?.[0]?.scheduledAt).utc()
        const date = isScheduled ? scheduledAtWithUtc.format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
        const hour = isScheduled ? scheduledAtWithUtc.format("HH:mm") : ""

        const isLastDate =
            (scheduledAtWithUtc.isSame(dayjs(), "day") || scheduledAtWithUtc.isBefore(dayjs(), "day")) &&
            scheduledAtWithUtc.isBefore(dayjs(), "hour")

        return {
            provider: this.getPostProvider(post.postItems),
            description: post?.description,
            locationIds: locationIdSingle?.length > 0 ? locationIdSingle : this.getPostLocationIds(post?.postItems),
            canAddDate: isScheduled,
            hour: hour ?? "",
            date: date ?? "",
            url: post?.callToAction?.value ?? "",
            isLastPost: isLastDate,
        }
    }

    formatOtherPostParams(post: PostResponse, day: string) {
        const postItemsByDate = groupByKey(post.postItems, "scheduledAt")
        return {
            medias: post?.medias,
            callToAction: post?.callToAction ?? null,
            postItems: postItemsByDate?.[day] ?? [],
        }
    }

    formatPosts(posts: Partial<PostResponse>[]) {
        const newPosts = []
        posts.forEach((post) => {
            const postItemsByDate = groupByKey(post.postItems, "scheduledAt")
            for (const [scheduledAt, postItems] of Object.entries(postItemsByDate)) {
                newPosts.push({
                    ...post,
                    postItems: postItems,
                    status: this.getPostStatus(postItems as IPostItems[]),
                    provider: this.getPostProvider(postItems as IPostItems[]),
                    locationIds: this.getPostLocationIds(postItems as IPostItems[]),
                })
            }
        })
        return newPosts
    }

    groupPostsByDay(posts: Partial<PostEntry>[]): PostByDate {
        return posts.reduce((acc, post) => {
            const currentPost = post?.postItems ?? []
            const scheduledAt = currentPost[0]?.scheduledAt
            // Extract the date part from the postItems field (YYYY-MM-DD)
            if (currentPost && currentPost.length && typeof currentPost === "object" && scheduledAt) {
                const formattedDate = scheduledAt.slice(0, 10)

                if (!acc[formattedDate]) {
                    acc[formattedDate] = []
                }

                //Verify if post is live or inprogress and group by originalDate
                if (["LIVE", "IN_PROGRESS"].includes(this.getPostStatus(post?.postItems))) {
                    const uniqueOriginalDates = [...new Set(currentPost.map((item) => item.scheduledAt.slice(0, 10)))]
                    if (uniqueOriginalDates?.length > 0) {
                        uniqueOriginalDates.forEach((originalDate: string) => {
                            const formattedOriginalDate = originalDate.slice(0, 10)

                            if (!acc[formattedOriginalDate]) {
                                acc[formattedOriginalDate] = []
                            }
                            acc[formattedOriginalDate].push(post)
                        })
                    }
                } else {
                    acc[formattedDate].push(post)
                }

                // Sort the posts for the current date
                acc[formattedDate].sort((a, b) => {
                    const statusOrder = { LIVE_W_ERROR: 0, DRAFT: 1, SCHEDULED: 2, IN_PROGRESS: 2, LIVE: 2 }

                    // Compare by status
                    const statusComparison = statusOrder[a.status] - statusOrder[b.status]
                    if (statusComparison !== 0) {
                        return statusComparison
                    }

                    // Compare by scheduledAt
                    return dayjs(a.postItems[0]?.scheduledAt).diff(dayjs(b.postItems[0]?.scheduledAt))
                })
            }

            return acc
        }, {} as PostByDate)
    }

    getMaxDateByLocationIds(locationIds: string[], listBusiness: SelectType[]) {
        const datesNow = []
        const locations = listBusiness.filter((item) => locationIds.includes(item.value as string))
        const today = dayjs().format("YYYY-MM-DD HH:mm:ss")
        locations.forEach((item) => {
            if (item?.timezone) {
                datesNow.push(dayjs(today).tz(item?.timezone))
            }
        })

        if (datesNow.length > 0) {
            const convertedDates = datesNow.map((date) => dayjs.utc(date))
            // Find the maximum date
            const maxDate = dayjs.max(convertedDates)
            return maxDate
        }
        return dayjs()
    }

    mappedLocationProvider(providers: Provider[], listBusiness: SelectType[]) {
        let res = {
            facebook: [],
            google: [],
            instagram: [],
        }

        const providersData = providers?.length > 0 ? providers : ["null"]
        if (listBusiness) {
            providersData.map((provider) => {
                listBusiness.map((business) => {
                    const statusKeyName = statusProviderName[provider]
                    const status = business.locationState?.[statusKeyName]
                    if (status && status !== "DISCONNECTED") {
                        res[provider].push(business.value)
                    }
                })
            })
        }
        return res
    }
}

export default new SocialService()
