import { imgListExtension, TO_RADIANS, videoListExtension } from "@/data/constants"
import { centerCrop, makeAspectCrop, PixelCrop } from "react-image-crop"

export interface MediaObject {
    file: File
    type: string
    googleChecked: boolean
    fbChecked: boolean
}

export interface FileCount {
    image: number
    video: number
}

export interface ErrorDuplicate {
    duplicate: boolean
}

export interface ErrorUpload {
    error: boolean
}

export interface ResponseUpload {
    medias: {
        images: File[]
        video: File[]
    }
}

const lowerBoundImage = 0.8 // 4:5
const upperBoundImage = 1.91 // 1.91:1
const headerListType = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "video/mp4",
    "video/mov",
    "video/m4v",
    "video/quicktime",
]

class MediaService {
    canvasPreview = (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0) => {
        const ctx = canvas.getContext("2d")

        if (!ctx) {
            throw new Error("No 2d context")
        }
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const pixelRatio = window.devicePixelRatio
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = "high"

        const cropX = crop.x * scaleX
        const cropY = crop.y * scaleY

        const rotateRads = rotate * TO_RADIANS
        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2

        ctx.save()
        ctx.translate(-cropX, -cropY)
        ctx.translate(centerX, centerY)
        ctx.rotate(rotateRads)
        ctx.scale(scale, scale)
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
        )

        ctx.restore()
    }

    centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        )
    }

    blobToFile = (theBlob: Blob, fileName: string): File => {
        const b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        Object.setPrototypeOf(b, File.prototype)
        return theBlob as File
    }

    getMediaProvider = (googleChecked, fbChecked): any[] => {
        const provider = []
        if (googleChecked && fbChecked) {
            provider.push("google", "facebook")
        }
        if (!googleChecked && fbChecked) {
            provider.push("facebook")
        }
        if (googleChecked && !fbChecked) {
            provider.push("google")
        }
        return provider
    }

    isFileValid = (mediaFile: File, mediaExtension: string, size: { image: number; video: number }): boolean => {
        const isImageValid =
            imgListExtension.includes(mediaExtension.toLowerCase()) && mediaFile.size <= size.image * 1024 * 1024
        const isVideoValid =
            videoListExtension.includes(mediaExtension.toLowerCase()) && mediaFile.size <= size.video * 1024 * 1024
        const isSizeValid = mediaFile.size >= 10 * 1024
        return (isImageValid || isVideoValid) && isSizeValid
    }

    isMediaFileUnique = (mediasLocal: any, mediaFile: File): boolean => {
        return !mediasLocal?.some((media) => media.file?.name === mediaFile.name)
    }

    createMediaObject = (
        mediaFile: File,
        mediaExtension: string,
        googleChecked?: boolean,
        fbChecked?: boolean
    ): MediaObject => {
        return {
            file: mediaFile,
            type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            googleChecked,
            fbChecked,
        }
    }

    isRatioWithinRange = (ratioMedia: string) => {
        const [numerator, denominator] = ratioMedia.split(":").map(Number)
        const ratio = numerator / denominator

        return ratio >= lowerBoundImage && ratio <= upperBoundImage
    }

    mediaUploadListWithNetwork = async (
        mediasLocal: any,
        mediaFiles: FileList,
        googleChecked: boolean,
        fbChecked: boolean
    ): Promise<MediaObject[] | ErrorDuplicate> => {
        const preFileList = []
        for (const mediaFile of Array.from(mediaFiles)) {
            const mediaExtension = mediaFile?.name?.split(".").pop().toLowerCase()
            if (!this.isMediaFileUnique(mediasLocal, mediaFile) || !headerListType.includes(mediaFile?.type)) {
                return { duplicate: true }
            }
            if (!this.isFileValid(mediaFile, mediaExtension, { image: 5, video: 50 })) {
                continue
            }
            if (imgListExtension.includes(mediaExtension.toLowerCase())) {
                const img = new Image()
                img.src = URL.createObjectURL(mediaFile)

                await new Promise((resolve, reject) => {
                    img.onload = resolve
                    img.onerror = reject
                })

                if (img.height >= 250 && img.width >= 250) {
                    const mediaObject = this.createMediaObject(mediaFile, mediaExtension, googleChecked, fbChecked)
                    preFileList.push(mediaObject)
                }
            } else {
                const mediaObject = {
                    file: mediaFile,
                    type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
                    googleChecked,
                    fbChecked,
                }
                preFileList.push(mediaObject)
            }
        }

        return preFileList.length === mediaFiles.length ? preFileList : []
    }

    mediaUploadList = async (mediasLocal: any, mediaFiles: FileList): Promise<ResponseUpload & ErrorUpload> => {
        const preFileList = {
            images: [],
            video: [],
        }

        for (const mediaFile of Array.from(mediaFiles)) {
            const mediaExtension = mediaFile?.name?.split(".").pop().toLowerCase()
            if (!this.isMediaFileUnique(mediasLocal, mediaFile) || !headerListType.includes(mediaFile?.type)) {
                continue
            }
            
            if (!this.isFileValid(mediaFile, mediaExtension, { image: 10, video: 50 })) {
                return { medias: preFileList, error: true }
            }
            if (imgListExtension.includes(mediaExtension.toLowerCase())) {
                const img = new Image()
                img.src = URL.createObjectURL(mediaFile)

                await new Promise((resolve, reject) => {
                    img.onload = resolve
                    img.onerror = reject
                })

                if (img.height >= 250 && img.width >= 250) {
                    const mediaObject = this.createMediaObject(mediaFile, mediaExtension)

                    preFileList.images.push(mediaObject)
                }
            } else {
                const mediaObject = {
                    file: mediaFile,
                    type: "VIDEO",
                }

                preFileList.video.push(mediaObject)
            }
        }

        return { medias: preFileList, error: false }
    }
}

export default new MediaService()
