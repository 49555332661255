import useCalendar from "@/hooks/use-calendar"
import useResponsive from "@/hooks/use-responsive"
import { SchedulerContentProps, SchedulerHeaderProps, SchedulerItemProps, SchedulerObject } from "@/types/scheduler"
import { capitalize, IconButton, Stack, Typography } from "@mui/material"
import { useEffect } from "react"
import Icon from "../base/Icon"
import { SchedulerItem } from "./item"
import { useStyles } from "./styles"

const SchedulerHeader = ({ month, onNextMonth, onPreviousMonth }: SchedulerHeaderProps) => {
    const { isMobile } = useResponsive()

    return (
        <Stack flexDirection={"row"} gap={2} alignItems={"center"} paddingX={2} paddingY={isMobile ? 3 : 1}>
            <Stack flexDirection={"row"} gap={2}>
                <IconButton onClick={onPreviousMonth}>
                    <Icon name="arrowLeft" sx={{ width: 24, height: 24 }} />
                </IconButton>
                <IconButton onClick={onNextMonth}>
                    <Icon name="arrowRight" sx={{ width: 24, height: 24 }} />
                </IconButton>
            </Stack>
            <Typography variant="h3" minWidth={116}>
                {capitalize(month.format("MMMM YYYY"))}
            </Typography>
        </Stack>
    )
}

const SchedulerContent = ({ daysLabel, daysGrid, events, cellRenderer }: SchedulerContentProps) => {
    const { isMobile } = useResponsive()
    const { classes } = useStyles({ props: { isMobile } })
    return (
        <Stack className={classes.schedulerContent} flexDirection={"column"}>
            {!isMobile && (
                <Stack flexDirection={"row"}>
                    {daysLabel.map((day) => (
                        <Stack key={day} sx={{ width: "100%" }}>
                            <Typography variant="subtitle2" color="action.active" align="center">
                                {capitalize(day)}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            )}
            {daysGrid.map((week, index) => {
                const dates = isMobile ? week.dates.filter((date) => date.isCurrentMonth) : week.dates
                return (
                    <Stack key={index} flexDirection={isMobile ? "column" : "row"}>
                        {dates.map((date) => (
                            <>
                                {!cellRenderer ? (
                                    <SchedulerItem key={date.day} date={date} events={events} />
                                ) : (
                                    cellRenderer({ date, events })
                                )}
                            </>
                        ))}
                    </Stack>
                )
            })}
        </Stack>
    )
}

type SchedulerComponentProps = {
    data: SchedulerObject
    onMonthChange?: (month: string) => void
    defaultMonth?: string
    cellRenderer?: ({ date, events }: SchedulerItemProps) => void
}

const SchedulerComponent = ({ data, onMonthChange, defaultMonth = null, cellRenderer }: SchedulerComponentProps) => {
    const { currentMonth, nextMonth, prevMonth, getHeaderDays, arrayOfDays } = useCalendar({ defaultMonth })

    useEffect(() => {
        onMonthChange(currentMonth.format("YYYY-M"))
    }, [currentMonth])

    return (
        <>
            <SchedulerHeader month={currentMonth} onNextMonth={nextMonth} onPreviousMonth={prevMonth} />
            <SchedulerContent
                daysLabel={getHeaderDays()}
                daysGrid={arrayOfDays}
                events={data}
                cellRenderer={cellRenderer}
            />
        </>
    )
}

export default SchedulerComponent
