import { SocialsApi } from "@/api"
import { SocialService } from "@/services"
import SocialStore from "@/store/social"
import UserSessionStore from "@/store/user-session"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"

const usePageLogic = () => {
    const { profile } = UserSessionStore()
    const { update: updateSocial, businesses, currentMonth } = SocialStore()
    const { notif } = useNotification()
    const { t } = useTranslation()

    const fetchPosts = useCallback(async () => {
        const dateFormatted = currentMonth.split("-")
        const year = parseInt(dateFormatted?.[0])
        const month = parseInt(dateFormatted?.[1])

        if (profile?.uid && businesses) {
            const date = new Date()
            const currentMonth = month ?? date.getMonth() + 1
            const currentYear = year ?? date.getFullYear()
            const resp = await SocialsApi.fetchPosts({
                month: currentMonth,
                year: currentYear,
                locationIds: businesses,
                fields: "",
            })
            if (!resp?.error) {
                const posts = SocialService.formatPosts(resp)
                updateSocial({
                    posts: SocialService.groupPostsByDay(posts),
                    draftPosts: posts.filter((data) => data.status === "DRAFT"),
                })
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        }
    }, [profile, businesses, currentMonth])

    return {
        fetchPosts,
    }
}

export default usePageLogic
